@import '../../../../sass/abstracts/mixins';

.register-cont {
  padding-top: 8rem;
  padding-bottom: 2rem;
  background-color: #eff0f5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include largeScreen();

  .register-cont-block {
    height: 82vh;
    border-radius: 0.3rem;
    box-shadow: 0 16px 32px 0 rgba(31, 34, 51, 0.12),
      0 8px 16px 0 rgba(31, 34, 51, 0.04);
    background-color: #ffffff;
    width: 80%;
    display: flex;
    overflow: hidden;

    @include breakpoint(mobileonly) {
      width: 95%;
    }

    @include breakpoint(phablet) {
      width: 95%;
    }

    .main-org-cont {
      width: 100%;
      padding: 2rem 5rem;
      overflow: auto;
    }

    .part-1 {
      width: 50%;
      background-image: url('../../../../images/Auth/helpinghand.jpg');
      background-size: cover;
      height: auto;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      border-bottom-right-radius: 100px;

      @include breakpoint(mobileonly) {
        display: none;
      }

      @include breakpoint(phablet) {
        display: none;
      }

      .title {
        font-family: 'WickedGrit';
        font-size: 2.6rem;
        color: #ffffff;
      }
    }

    .part-2 {
      width: 50%;
      padding: 2rem 4rem;
      overflow-y: auto;
      margin-bottom: 1rem;

      @include breakpoint(mobileonly) {
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        color: #ffffff;
        overflow: auto;
      }

      @include breakpoint(phablet) {
        height: 100%;
        width: 100%;
        overflow: auto;
      }

      .part-2-header {
        .title {
          font-family: 'WickedGrit';
          font-size: 1.6rem;
          font-size: 20px;
          font-weight: 600;
          color: #424557;

          @include breakpoint(mobileonly) {
            width: 100%;
            text-align: center;
          }

          @include breakpoint(phablet) {
            width: 100%;
            text-align: center;
          }
        }

        .sub-title {
          font-family: 'Baloo Da 2';
          font-size: 14px;
          line-height: 1.25;
          color: var(--text-secondary);
          margin-top: 0.5rem;

          @include breakpoint(mobileonly) {
            width: 100%;
            text-align: center;
            margin-bottom: 2rem;
          }

          @include breakpoint(phablet) {
            width: 100%;
            text-align: center;
            margin-bottom: 2rem;
          }
        }
      }

      .charity-form-cont {
        padding: 0 !important;
      }

      .form-cont-wrapper{
        display: flex;
        flex-direction: column;
        height: 85;
      }

      .form-cont {
        padding: 1rem 0;

        .name-form-cont {
          display: flex;
          justify-content: space-between;
          margin: 1.2rem 0;

          @include breakpoint(mobileonly) {
            flex-direction: column;
          }
        }

        .name-form-group {
          width: 45%;

          @include breakpoint(mobileonly) {
            width: 100%;
            margin-bottom: 2rem;
          }
        }

        .label {
          font-family: 'Baloo Da 2';
          font-size: 1.4rem;
          color: var(--text-secondary);
          font-weight: 500;
        }

        .email-form-cont {
          position: relative;

          .image {
            position: absolute;
            top: 59%;
            font-size: 1.4rem;
            right: 1.2rem;
            cursor: pointer;
            color: var(--text-secondary);
          }
        }

        .terms-cont {
          margin-top: 1.5rem;
          .terms-content {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0.2rem;
            font-family: 'Baloo Da 2';
            font-size: 12.5px;
            color: var(--text-secondary);

            span {
              margin-left: 0.5rem;
              cursor: pointer;
              font-family: 'Baloo Da 2';
              margin-top: -0.12rem;
            }

            .link {
              color: var(--highlight-primary);
            }
          }
        }

        .charity-form-cont {
          .success-message {
            font-family: 'Baloo Da 2';
            font-size: 1.2rem;
            color: var(--vivid-green);
            margin-bottom: 1.5rem;
          }

          .form-group {
            margin: 1.6rem 0;

            .charity-label {
              font-family: 'Baloo Da 2';
              font-size: 1.4rem;
              font-weight: bold;
              color: var(--text-secondary);
              width: 100%;
            }

            .charity-type-cont {
              display: flex;
              justify-content: space-between;

              .charity-type-btn {
                width: 192px;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                border: solid 2px #697084;
                background-color: #ffffff;
                font-family: 'Baloo Da 2';
                font-size: 14px;
                color: #697084;
                cursor: pointer;

                &:hover {
                  border: solid 2px #fc4ae8;
                }
              }

              .active-charity-type {
                border: solid 2px #fc4ae8;
              }
            }

            .label {
              font-family: 'Baloo Da 2';
              font-size: 1.4rem;
              color: var(--text-secondary);
            }

            .icon {
              filter: opacity(0.5);
            }
          }
        }

        .btn {
          width: 15rem;
          font-family: 'Baloo Da 2';
          font-size: 1.4rem;
          color: #ffffff;
          font-weight: bold;
          border-radius: 6px;
          height: 2.5rem;
        }

      }
      .login-cont {
        margin-top: auto;
        font-family: 'Baloo Da 2';
        font-size: 1.2rem;
        color: var(--text-secondary);
        font-weight: bold;
        margin-left: 0.5rem;

        a {
          color: var(--highlight-primary) !important;
          cursor: pointer;
        }
      }

      .choose-user-wrapper {
        font-family: 'Baloo Da 2', 'sans-serif';
        margin-top: 3rem;
        display: flex;
        flex-direction: column;

        .login-nav{
          margin-top: auto;
          font-family: 'Baloo Da 2';
          font-size: 1.2rem;
          color: var(--text-secondary);
          font-weight: bold;

          a {
            color: var(--highlight-primary) !important;
            cursor: pointer;
          }

          @include breakpoint(mobileonly) {
            margin-bottom: 2rem;
          }
        }

        .choose-user {
          border-radius: 0.6rem;
          margin-bottom: 2rem;

          @include breakpoint(mobileonly) {
            padding: 1rem;
          }

          &__header {
            position: relative;
            height: 4rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
          }

          &__description {
            font-size: 1.2rem;
            font-weight: 400;
            color: vaR(--text-secondary);
          }

          [type='radio']:checked,
          [type='radio']:not(:checked) {
            position: absolute;
            left: -9999px;
            display: none;
          }
          [type='radio']:checked + label,
          [type='radio']:not(:checked) + label {
            position: relative;
            padding: 0.2rem 5rem;
            cursor: pointer;
            line-height: 3.5rem;
            display: inline-block;
            color: var(--text-primary);
            font-size: 1.4rem;
            font-weight: 600;

            @include breakpoint(mobileonly) {
              padding-left: 4rem;
            }
          }
          [type='radio']:checked + label:before,
          [type='radio']:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 4rem;
            height: 4rem;
            border: 1px solid var(--highlight-primary);
            border-radius: 100%;
            background: #fff;

            @include breakpoint(mobileonly) {
              width: 3rem;
              height: 3rem;
            }
          }
          [type='radio']:checked + label:after,
          [type='radio']:not(:checked) + label:after {
            content: '';
            width: 3rem;
            height: 3rem;
            background: var(--highlight-primary);
            position: absolute;
            top: .5rem;
            left: .5rem;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;

            @include breakpoint(mobileonly) {
              width: 2rem;
              height: 2rem;
            }
          }
          [type='radio']:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }
          [type='radio']:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
  }
}
