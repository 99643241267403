@import '../../../sass/abstracts/mixins';

.campaign-cont {
  padding-top: 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include largeScreen();

  .campaign-header {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;

    .contents {
      display: flex;
      align-items: center;
      width: 100%;

      .headind-text {
        font-size: 1.66rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #424557;
        font-family: 'Baloo Da 2';
      }

      .drop-menu {
        margin-left: 2rem;
        width: 10rem;
        position: relative;
        height: 3rem;
        background-color: var(--bg-1);
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-family: 'Baloo Da 2';
        padding: 1rem;
        border-radius: 0.3rem;

        .drop-name {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          cursor: pointer;
        }

        .drop {
          position: absolute;
          top: 105%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          background-color: white;
          padding: 1rem;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          left: 0;
          z-index: 9;

          .items-drop {
            margin-bottom: 0.5rem;
            cursor: pointer;

            &:hover {
              color: var(--highlight-primary);
            }
          }
        }
      }
    }
  }

  .causes {
    width: 100%;
  }

  .campaign-cause-cont {
    .cause-container {
      background-color: white;
      padding: 0 0 3rem 0;

      .cards-container {
        width: 100%;

        .cards {
          position: relative;
          width: calc((100% - 6rem) / 3);
          height: 31rem;
          overflow: hidden;
          border-radius: 0.6rem;
          border: solid 2px #eff0f5;
          cursor: pointer;
          transition: all 0.2s linear;
          margin-right: 2rem;

          @include breakpoint(mobileonly) {
            width: calc((100% - 6rem) / 1);
            margin-right: 0;
          }

          @include breakpoint(phablet) {
            width: calc((100% - 6rem) / 2);
            margin-right: 1rem;
          }
        }

        .create-card {
          width: calc((100% - 6rem) / 3);
          height: 31rem;
          overflow: hidden;
          border-radius: 0.6rem;
          border: solid 2px #eff0f5;
          cursor: pointer;
          transition: all 0.2s linear;
          margin-right: 2rem;

          @include breakpoint(mobileonly) {
            width: calc((100% - 6rem) / 1);
            margin-right: 0;
          }

          @include breakpoint(phablet) {
            width: calc((100% - 6rem) / 2);
            margin-right: 1rem;
          }
        }

        .drop-menu {
          margin-left: 2rem;
          width: 9rem;
          height: 3rem;
          background-color: white;
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          font-family: 'Baloo Da 2';
          padding: 1rem;
          border-radius: 0.3rem;
          position: absolute;
          right: 2rem;
          z-index: 9;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
          top: 4%;

          .drop-name {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            cursor: pointer;
          }

          .drop {
            position: absolute;
            top: 105%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            background-color: white;
            padding: 1rem;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            left: 0;
            z-index: 9;

            .items-drop {
              margin-bottom: 0.5rem;
              cursor: pointer;

              &:hover {
                color: var(--highlight-primary);
              }
            }
          }
        }

        .create {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          justify-content: center;
          cursor: pointer;

          .plus-icon-cont {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            border: solid 2px #e1e2ea;
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
              font-size: 5rem;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #979797;
              margin-top: -0.5rem;
              font-family: 'Wikedgrid';
            }
          }

          .create-text {
            font-family: 'Baloo Da 2';
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ed2bac;
            margin-top: 2rem;
          }
        }
      }
    }
  }
}

.campaign-cont-first {
  background-color: #ffffff;
  padding: 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .first-time {
    .campaign-plus-btn {
      height: 6.6rem;
      width: 6.6rem;
      border-radius: 3.3rem;
      border: 2px solid var(--bg-5);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;

      @include breakpoint(mobileonly) {
        height: 6rem;
        width: 5.7rem;
      }
      
      .image {
        font-size: 5rem;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #979797;
        margin-top: -0.5rem;
        font-family: “Wikedgrid”;
      }
    }
    background-repeat: no-repeat;
    width: 90rem;
    height: 20.8rem;
    font-family: 'Baloo Da 2';
    font-size: 1.6rem;
    color: #697084;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-size: cover;

    @include breakpoint(mobileonly) {
      width: 98%;
    }

    @include breakpoint(phablet) {
      width: 98%;
      border-radius: 0.3rem;
    }
  }
}

.create-campaign-btn {
  height: 3.3rem;
  width: 16.3rem;
  border: solid 2px var(--bg-5);
  background-color: #ffffff;
  border-radius: 0.6rem;
  color: #565a6c !important;
  text-transform: none;
  margin-top: 1.5rem;
}

.ad-banner-campaign {
  position: absolute;
  top: 0rem;
}
