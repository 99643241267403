@import '../../../sass/abstracts/mixins';

.cco-profile-cont {
  padding-top: 6.66rem;
  display: flex;
  height: 100vh;
  font-family: 'Baloo Da 2', 'sans-serif';
  @include largeScreen();

  &__sidebar {
    padding: 2rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(179, 179, 179, 0.5);
    position: fixed;
    height: 100%;
    background: var(--text-primary);
    width: 20rem;

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(phablet) {
      display: none;
    }
  }

  &__item {
    font-size: 1.4rem;
    padding: 1rem 0;
    cursor: pointer;
    color: var(--bg-6) !important;

    &:hover {
      a {
        color: var(--highlight-primary) !important;
      }
    }

    &--active {
      color: var(--highlight-primary) !important;
      font-weight: 600;
    }
  }

  &__content {
    margin-left: 20rem;
    padding: 2rem 2rem;
    width: 100%;
    position: relative;

    @include breakpoint(mobileonly) {
      margin-left: 0;
      padding: 4rem 2rem;
    }

    @include breakpoint(phablet) {
      margin-left: 0;
      padding: 4rem 2rem;
    }
  }

  .profile-part-1 {
    width: 75%;
    margin: 2rem 0;

    @include breakpoint(mobileonly) {
      width: 90%;
    }

    @include breakpoint(phablet) {
      width: 90%;
    }

    .items {
      padding: 1rem 0;
      display: flex;
      width: 100%;

      @include breakpoint(mobileonly) {
        justify-content: space-between;
        flex-direction: column;
      }

      @include breakpoint(phablet) {
        justify-content: space-between;
        flex-direction: column;
      }

      .item {
        font-family: 'Baloo Da 2';
        font-size: 1.6rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #565a6c;
        margin-right: 3rem;
        cursor: pointer;
        padding: 1rem 0;
        border-bottom: 0.2rem solid #eaeaea;

        @include breakpoint(mobileonly) {
          margin-right: 0;
        }

        @include breakpoint(phablet) {
          margin-right: 0;
        }
      }

      .active {
        color: var(--highlight-primary);
        border-bottom: 0.2rem solid var(--highlight-primary);
      }
    }
  }

  .profile-part-2 {
    background-color: white;
    width: 75%;

    @include breakpoint(mobileonly) {
      width: 90%;
    }

    @include breakpoint(phablet) {
      width: 90%;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: #ccc;
    }
  }
}
