@font-face {
  font-family: NeoSansStd;
  src: local(NeoSansStd-Regular),
    url('./NeoSansStd-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: WickedGrit;
  src: local(WickedGrit),
    url('./WickedGrit.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Athiti-Regular;
  src: local(WickedGrit),
    url('./Athiti-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Athiti-Bold;
  src: local(WickedGrit),
    url('./Athiti-Bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Athiti-SemiBold;
  src: local(WickedGrit),
    url('./Athiti-SemiBold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Athiti-Medium;
  src: local(WickedGrit),
    url('./Athiti-Medium.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family: Athiti-Light;
  src: local(WickedGrit),
    url('./Athiti-Light.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
