.form-control:focus {
  color: var(--text-secondary) !important;
  background-color: #fff;
  border-color: #fff !important;
  outline: 0;
  box-shadow: 0 0 0 0.16rem #e1e2ea !important;
}

.form-control {
  font-size: 1.4rem !important;
  height: 3rem;
  border-radius: 0.3rem;
  border: solid 2px #e1e2ea;
  font-family: "Baloo Da 2";
  color: var(--text-secondary);
}

.form-filled {
  border-color: var(--light-magenta) !important;
  font-style: normal;
}

