.custom-select {
  &__control {
    border-radius: 0.3rem;
    border: solid 0.06rem var(--text-ternary);
    width: 100%;
    min-height: 3rem !important;
    max-height: 3rem !important;
    font-size: 1.2rem;
    box-shadow: none !important;
    border-width: 0.03rem !important;
    font-family: "Baloo Da 2";
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    background-color: var(--bg-1) !important;
    color: var(--text-secondary) !important;

    &--menu-is-open {
      border-radius: 0.3rem 0.3rem 0 0 !important;
      outline: none !important;
      min-height: 3rem !important;
      max-height: 3rem !important;
      border-width: 0.03rem !important;
      border-style: none !important;
      font-size: 1rem;
      border: 0.125rem solid var(--text-ternary);
      font-size: 1.4rem;
      background-color: white;
      z-index: 9 !important;

      &:hover {
        border: 0.03rem solid var(--text-ternary);
      }
    }
  }

  &__control--is-disabled {
    background-color: var(--bg-6) !important;
    border: 0.1rem solid var(--bg-5) !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    img {
      opacity: 0.1;
    }
  }

  &__clear-indicator {
    display: none !important;
  }

  &__indicator-separator {
    background-color: transparent !important;
  }
}

.css-1hb7zxy-IndicatorsContainer {
  height: 3rem !important;
}

.css-g1d714-ValueContainer {
  height: 3rem !important;
}

.css-26l3qy-menu {
  border-radius: 0 0 0.3rem 0.3rem !important;
  border: 0.03rem solid var(--text-ternary) !important;
  width: 100% !important;
  font-size: 1.2rem;
  top: 80% !important;
  border-top: none !important;
  box-shadow: none !important;
  border-width: 0.03rem;
  color: var(--text-secondary);
  background-color: white;
}

.css-4ljt47-MenuList {
  padding-top: 0.4rem !important;
  border-radius: 0 0 0.3rem 0.3rem !important;
}

.css-9gakcf-option {
  background-color: var(--text-secondary) !important;
  color: var(--bg-1) !important;
  margin-top: 0.5rem !important;
  color: white;
}

.css-1pahdxg-control {
  background-color: var(--bg-1) !important;
  border: 0.03rem solid var(--text-ternary) !important;
}

.custom-select__single-value {
  color: var(--text-secondary) !important;
}