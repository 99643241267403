@import '../../../sass/abstracts/mixins';

.create-campaign-cont {
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  background-color: white;

  @include breakpoint(mobileonly) {
    padding: 0;
  }

  @include breakpoint(phablet) {
    padding: 0;
  }

  .campaigns-info {
    width: 100%;

    .steps-count {
      font-size: 1.6rem;
      color: #424557;
      font-family: 'Baloo Da 2';
      font-weight: 600;
      margin-top: 3.6rem;
      opacity: 0.4;
    }

    .campaign-part-1 {
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      @include breakpoint(mobileonly) {
        flex-direction: column;
        align-items: center;
        height: 100%;
      }

      @include breakpoint(phablet) {
        flex-direction: column;
        align-items: center;
        height: 100%;
      }

      .campaign-part-1-1 {
        width: 20rem;
        height: 20rem;
        border-radius: 6px;
        background-color: #f6f6f6;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        margin-top: 2rem;

        @include breakpoint(mobileonly) {
          flex-direction: column;
          align-items: center;
          height: 28rem;
          width: 100%;
          margin-bottom: 1rem;
        }

        @include breakpoint(phablet) {
          flex-direction: column;
          align-items: center;
          height: 20rem;
          width: 75%;
          margin-bottom: 1rem;
        }

        .upload-pic {
          font-family: 'Baloo Da 2';
          font-size: 1.2rem;
          color: #697084;
          cursor: pointer;

          .img {
            height: 1.4rem;
          }
        }

        span {
          margin-left: 0.5rem;
          font-family: 'Baloo Da 2';
          font-size: 1.4rem;
          color: var(--text-secondary);
        }

        .img {
          height: 1.4rem;
        }

        .img-cont {
          position: relative;
          height: 100%;
          width: 100%;
        }

        .image {
          height: 100%;
          width: 100%;
        }

        .middle {
          transition: 0.5s ease;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
          cursor: pointer;
        }

        .img-cont:hover .image {
          opacity: 0.3;
        }

        .img-cont:hover .middle {
          opacity: 1;
        }

        .text {
          background-color: #4caf50;
          color: white;
          font-size: 16px;
          padding: 16px 32px;
        }
      }

      .campaign-part-1-2 {
        width: 72%;
        @include breakpoint(mobileonly) {
          width: 100%;
        }
        .campaign-part-2-1 {
          margin-bottom: 1rem;
          .label {
            font-family: 'Baloo Da 2';
            font-size: 1.2rem;
            color: #697084;
          }

          .date-time {
            .react-datepicker-wrapper {
              width: 100%;
            }
            input {
              height: 4rem;
              border-radius: 10px;
              border: solid 2px #e1e2ea;
              width: 100%;
              padding: 0 1rem;
              color: var(--text-secondary);
              font-family: 'Baloo Da 2';
              font-size: 1.3rem;
            }
          }

          .form-control {
            height: 4rem;
            border-radius: 10px;
            border: solid 2px #e1e2ea;
          }
        }

        .campaign-part-2-2 {
          margin: 1rem 0;
          .label {
            font-family: 'Baloo Da 2';
            font-size: 1.2rem;
            color: #697084;
          }

          .text-box {
            min-height: 12.6rem;
            max-height: 12.6rem;
            border-radius: 10px;
            border: solid 2px #e1e2ea;
            width: 100%;
            font-family: 'Baloo Da 2';
            padding: 1rem 0.5rem;
            font-size: 1.4rem;
            color: var(--text-secondary);
          }

          .text-box:focus {
            color: var(--text-secondary) !important;
            background-color: #fff;
            border-color: #fff !important;
            outline: 0;
            box-shadow: 0 0 0 0.16rem var(--light-magenta) !important;
          }
        }
      }
    }

    .head {
      margin: 1rem 0;

      .title {
        font-family: 'WickedGrit';
        font-size: 1.6rem;
        color: var(--text-primary) !important;
        margin: 0.5rem 0;
      }

      .description {
        font-family: 'Baloo Da 2';
        font-size: 1.4rem;
        color: #697084;
      }
    }

    .items {
      display: flex;

      @include breakpoint(mobileonly) {
        flex-direction: column;
      }

      @include breakpoint(phablet) {
        flex-direction: column;
      }

      .box {
        width: 33.5rem;
        height: 21.1rem;
        border-radius: 6px;
        border: solid 2px #e1e2ea;
        margin: 2rem 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        cursor: pointer;
        margin-right: 2rem;

        @include breakpoint(mobileonly) {
          height: 100%;
          padding: 2rem;
          flex-direction: column;
          width: 100%;
        }

        @include breakpoint(phablet) {
          height: 100%;
          padding: 2rem;
          flex-direction: column;
          width: 100%;
        }

        .logo-cont {
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            height: 5rem;
          }
        }

        .info {
          padding: 2rem 0;
          text-align: left;

          @include breakpoint(mobileonly) {
            width: 100%;
          }

          @include breakpoint(phablet) {
            width: 100%;
          }

          .anchor-tag {
            color: var(--light-magenta) !important;
            text-decoration: underline !important;
            cursor: pointer;
          }

          .information {
            font-family: 'Baloo Da 2';
            font-size: 1.4rem;
            color: #697084;
          }

          .btn-container {
            display: flex;
            margin-top: 2rem;
            justify-content: center;

            .text {
              font-family: 'Baloo Da 2';
              font-size: 1.3rem;
              background-color: #fff !important;
              color: var(--light-magenta);
              padding: 0;
            }

            .btn {
              height: 2.8rem;
              width: 11.3rem;
              margin: 0;
            }
          }
        }
      }
    }

    .form-cont {
      margin-top: 2rem;
      .part-2 {
        position: relative;

        .label {
          font-family: 'Baloo Da 2';
          font-size: 1.2rem;
          color: #697084;
        }

        .form-control {
          height: 56px;
          border-radius: 10px;
          border: solid 2px #e1e2ea;
        }

        .btn {
          width: 11rem;
          height: 3.33rem;
          margin: 0;
          position: absolute;
          top: 41%;
          right: 0.5rem;
          border-radius: 6px;
          height: 3.33rem;
        }

        .link {
          color: var(--text-four);
          font-family: 'Baloo Da 2';
          font-size: 1.2rem;
        }
      }
    }

    .wishlist-items {
      .title {
        font-family: 'Baloo Da 2';
        font-size: 1.4rem;
        color: var(--text-secondary);
        margin-bottom: 3rem;
      }

      .wishlist-item-cont {
        min-height: 27rem;
        max-height: 35rem;
        overflow: auto;
        margin: 2rem 0;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
          background-color: #323649;
        }

        .item {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          padding: 1.5rem 2rem;
          color: var(--text-secondary);
          font-family: 'Baloo Da 2';

          @include breakpoint(mobileonly) {
            flex-direction: column;
            align-items: flex-start;
            font-size: 1.6rem;
          }

          .logo {
            width: 20%;
            @include breakpoint(mobileonly) {
              width: 100%;
              margin-bottom: 1rem;
            }

            .image {
              height: 5rem;
            }
          }

          .description {
            width: 100%;

            @include breakpoint(mobileonly) {
              width: 100%;
              margin-bottom: 1rem;
            }
          }

          .price {
            width: 100%;

            @include breakpoint(mobileonly) {
              width: 100%;
              margin-bottom: 1rem;
            }
          }

          .quantity {
            width: 100%;
          }
        }
      }
    }

    .btn-container {
      display: flex;
    }
  }
}

.create-campaign-step-3 {
  .create-campaign-cont {
    padding-top: 10rem;

    .campaigns-info {
      width: 80%;
    }
  }
}
