@import "../../../sass/abstracts/mixins";

.dashboard-cont {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-3);
  
  .dashboard-header {
    font-size: 2.4rem;
    font-family: "WickedGrit";
    width: 88%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;

    @include breakpoint(mobileonly) {
     width: 100%;
     display: flex;
     justify-content: center;
    }

    @include breakpoint(phablet) {
     width: 100%;
     display: flex;
     justify-content: center;
    }
  }

  .chart-cont {
    width: 88%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(mobileonly) {
      width: 95%;
    }

    @include breakpoint(phablet) {
      width: 95%;
    }

    .charts {
      width: 30%;
      height: 30rem;
      margin-bottom: 2rem;
      box-shadow: 0 0.1rem 0.6rem 0 rgba(179, 179, 179, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      align-items: center;
      background-color: white;

      @include breakpoint(mobileonly) {
        width: 100%;
      }
  
      @include breakpoint(phablet) {
        width: 100%;
      }

      .title {
        font-family: "Baloo Da 2";
        font-size: 1.4rem;
        color: #697084;
        font-weight: bold;
        width: 90%;
        height: 10%;
        margin-bottom: 2rem;
      }

      svg {
        font-family: "Baloo Da 2" !important;
        font-weight: bold;

        text {
          font-family: "Baloo Da 2" !important;
          font-size: 1.1rem;
          color: var(--text-secondary) !important;
        }
      }
    }

    .charts-lg {
      width: 65%;

      @include breakpoint(mobileonly) {
        width: 35rem;
      }

      @include breakpoint(phablet) {
        width: 35rem;
      }
    }
  }
}
