@import '../../sass/abstracts/mixins';

html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: 'NeoSansStd', 'WickedGrit', 'Athiti-light', 'Athiti-Regular',
    'Athiti-Bold', 'Athiti-SemiBold', 'Athiti-Medium', 'Baloo Da 2';
  height: 100vh;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #323649;
  }
}

a {
  color: #565a6c !important;
  text-decoration: none !important;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.dropdown-toggle::after {
  border: solid black;
  border-width: 0 0.15rem 0.15rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 1rem;
  vertical-align: 0.2rem;
}

.dropdown-menu {
  left: 11%;
  border: 1px solid white;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(179, 179, 179, 0.5);
}

.nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@include breakpoint(mobileonly) {
  .show-mine {
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    height: 100vh;
    width: 100%;

    overscroll-behavior: contain;
  }
}

@include breakpoint(phablet) {
  .show-mine {
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    height: 100vh;
    width: 100%;
  }
}

.react-cookie-banner {
  position: fixed !important;
  bottom: 0 !important;
  background-color: var(--bg-1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem !important;

  .cookie-message {
    color: var(--text-primary) !important;
    font-size: 1.2rem;
  }

  .button-close {
    background-color: black !important;
    color: white !important;
  }
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.error-msg {
  font-size: 1.1rem;
  color: var(--light-magenta) !important;
  font-family: 'Baloo Da 2';
  margin: 0.5rem 0;
}

input:-internal-autofill-selected {
  color: var(--text-secondary) !important;
  background-color: white !important;
}

.css-26l3qy-menu {
  z-index: 9 !important;
}

.back-cont {
  width: 100%;
  padding: 1rem 5rem 0 5rem;

  .back {
    font-size: 1.6rem;
    font-family: 'Baloo Da 2';
    cursor: pointer;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    width: 6rem;

    i {
      margin-right: 0.5rem;
      font-size: 1.8rem;
    }

    &:hover {
      color: var(--light-magenta);
    }
  }
}

.cropper-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  z-index: 9999999;
  top: 0;
  width: 100%;
  left: 0;

  .cropper-modal-close {
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
    z-index: 999;

    path {
      fill: #ffffff;
    }
  }

  .cropper-cont {
    // width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ReactCrop {
      width: auto;

      &__image {
        height: 60vh;
        width: auto;
        display: flex;
        justify-content: center;
      }
    }

    @include breakpoint(mobileonly) {
      width: 34rem;
    }
  }
}

$column_one_width: 25%;
$column_two_width: 25%;
$column_three_width: 50%;

.fixed_headers {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: 'Baloo Da 2';
  border: 0.1rem solid var(--bg-3);

  thead {
    background-color: var(--bg-4);
    padding: 0 0.5rem 0 0;
    th {
      text-transform: uppercase;
      font-size: 1.4rem;
      color: var(--text-secondary);
      border-bottom: none;
    }
  }

  tr {
    border: 0.2rem solid var(--bg-3);
    border-bottom: 0.05rem solid var(--bg-3) !important;
  }

  tbody {
    padding: 0 0.5rem 0 0;

    td {
      font-size: 1.2rem;
      color: var(--text-secondary);
      border-top: none !important;
    }
  }

  tbody {
    overflow: auto;
    width: 100%;
    height: 50rem;
  }
}

.old_ie_wrapper {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  tbody {
    height: auto;
  }
}

.custom-small-sidebar {
  width: 25vw;
  background-color: white;
  top: 6rem !important;

  @include breakpoint(mobileonly) {
    width: 75vw;
  }

  @include breakpoint(phablet) {
    width: 75vw;
  }
}

.sweet-alert {
  width: 50rem !important;
  padding: 0.5rem 4.25rem !important;
  font-family: 'Baloo Da 2';
}

.congrats-container {
  font-family: 'Baloo Da 2';
  .cong-text-1 {
    font-size: 1.33rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #697084;
    font-family: 'Baloo Da 2';
    margin: 2rem 0;
  }

  .link {
    font-size: 1.33rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ed2bac !important;
  }

  .icon-conts {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }

  .icon-cont {
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 1rem;
  }

  i {
    font-size: 2.8rem;
    color: #424557;
    margin-right: 1.2rem;

    &:hover {
      color: #ed2bac;
    }
  }

  .sharelist {
    justify-content: center;
  }
}

.css-1pahdxg-control {
  border-color: none !important;
  box-shadow: none !important;
}

.swal-modal {
  font-family: 'Baloo Da 2';
}

.swal-title {
  padding: 0;
  margin-bottom: 0 !important;
}

.swal-text {
  text-align: center;
}

.swal-footer {
  display: flex;
  justify-content: center;
}

.swal-icon {
  width: 60px;
  height: 60px;
}

.swal-icon--warning__body {
  height: 28px;
}

.show-desktop-only {
  @include breakpoint(mobileonly) {
    display: none !important;
  }

  @include breakpoint(phablet) {
    display: none !important;
  }
}

.show-mobile-only {
  @include breakpoint(small-laptop) {
    display: none !important;
  }

  @include breakpoint(laptop) {
    display: none !important;
  }

  @include breakpoint(desktop) {
    display: none !important;
  }
}

.navbar-toggler {
  @include breakpoint(mobileonly) {
    display: none !important;
  }

  @include breakpoint(phablet) {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 814px) {
  .navbar-expand-md .navbar-collapse {
    display: none !important;
  }
}

.ad-banner {
  height: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Baloo Da 2';
  background-color: var(--text-secondary);
  color: white;
  font-weight: 500;
  z-index: 9;
  top: 6.6rem;
  width: 100%;
  left: 0;
  text-align: center;

  .how-links {
    text-decoration: underline !important;
    color: var(--light-magenta) !important;
    margin-left: 0.4rem;
    letter-spacing: 0.03rem;
    font-weight: 600;
  }

  @include breakpoint(mobileonly) {
    flex-direction: column;
    font-size: 1rem;
  }
}

.CookieConsent {
  font-family: 'Baloo Da 2', sans-serif !important;
  box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.3);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  
  a {
    text-decoration: underline;
    color: var(--highlight-primary) !important;
    font-weight: 800;
  }
}

.chartjs-render-monitor {
  // width: 100% !important;
  font-family: "Baloo Da 2" !important;
  height: 28rem !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
  border: solid 2px #fafafa !important;
}

textarea:disabled {
  background-color: #fafafa !important;
  opacity: 1;
  border: solid 2px #fafafa !important;
}

.nav-tabs {
  font-family: "Baloo Da 2";
}