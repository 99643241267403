@import '../../../sass/abstracts/mixins';

.my-profile-cont {
  font-family: 'Baloo Da 2';

  .sweet-alert {
    padding: 3rem 3rem 1rem 3rem !important;
    width: 40rem !important;

    .form-cont {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
      }

      .label {
        font-family: 'Baloo Da 2';
        font-size: 1.33rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #424557;
      }

      .form-control {
        height: 48px;
        border-radius: 10px;
        border: solid 2px #e1e2ea;
      }
    }
  }


  .my-profile-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--text-secondary);
  }
  
  .form-cont {
    display: flex;
    padding: 1rem 0 3rem 0;
    border-bottom: .4rem solid var(--bg-3);
    margin-bottom: 3rem;

    @include breakpoint(mobileonly) {
      flex-direction: column;
    }

    .profile-pic {
      width: 20rem;
      height: 20rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font-size: 14rem;
      text-transform: uppercase;
      margin-right: 5rem;
      color: var(--text-secondary);
    }

    .name-cont {
      margin-top: 1rem;
      font-size: 1.6rem;

      .text-1 {
        color: var(--text-secondary);
        margin-right: 1rem;
        font-size: 1.4rem;
        text-transform: uppercase;
      }

      .text-2 {
        color: var(--text-secondary);
      }
    }

    .btn-cont {
      display: flex;
      justify-content: center;
    }

    .btn-form {
      margin: 1rem;
      padding: 0 2rem;
    }

    .btn {
      width: 15rem;
      height: 40px;
      font-family: 'Baloo Da 2';
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: bold;
      text-transform: initial;
      background-color: #eff0f5 !important;
      color: var(--text-primary) !important;
      box-shadow: none !important;
      border-color: #eff0f5 !important;
    }
  }
}
