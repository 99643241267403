@import '../../../../sass/abstracts/mixins';

.cco-login-part-2 {
  padding: 3rem 4rem !important;
  width: 50%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #323649;
  }

  @include breakpoint(mobileonly) {
    width: 100%;
    padding: 0 !important;
  }

  .login-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint(mobileonly) {
      height: 82%;
      padding: 4rem 2rem;
    }

    .welcome {
      font-size: 1.6rem;
      color: var(--text-secondary);
      font-family: 'WickedGrit';
      margin-bottom: 2rem;
    }

    .form-group {
      position: relative;
      margin-bottom: 2rem;
      .label {
        font-family: 'Baloo Da 2';
        font-size: 1.4rem;
        color: var(--text-secondary);
      }

      .image {
        position: absolute;
        top: 60%;
        font-size: 1.4rem;
        right: 1.2rem;
        cursor: pointer;
        color: var(--text-secondary);
      }

      .form-control {
        height: 3.33rem;
      }
    }

    .btn {
      width: 10rem;
      font-family: 'Baloo Da 2';
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: bold;
      height: 2.8rem;
      border-radius: 0.3rem;
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .forgot-btn-cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .forgot-btn {
      width: 15rem;
      margin: 0;
    }

    .cancel-btn {
      margin-left: 2rem;
      font-size: 1rem;
      color: var(--text-secondary);
      text-transform: uppercase;
      height: 3rem;
      font-weight: bold;
      cursor: pointer;
      border: 0.1rem solid var(--text-secondary);
      width: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.3rem;

      &:hover {
        color: var(--light-magenta);
        border: 0.1rem solid var(--light-magenta);
      }
    }

    .forgot-password {
      color: var(--highlight-primary);
      cursor: pointer;
      font-size: 1.2rem;
      font-family: 'Baloo Da 2';
      width: 12rem;
    }

    .have-account {
      margin-top: 2rem;
      font-family: 'Baloo Da 2';
      font-size: 1.2rem;
      color: var(--text-secondary);

      a {
        color: var(--highlight-primary) !important;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }

  .part2 {
    background-image: url('../../../../images/Coming-Soon/bottom-bcg2.png');
    background-size: contain;
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;

    @include breakpoint(mobileonly) {
      height: 10rem;
    }
  }
}
