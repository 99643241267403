@import '../../../sass/abstracts/mixins';
.organization-container {
  .edit-cont {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .edit-btn {
      height: 3.33rem;
      width: 10rem;
      border-radius: 0.4rem;
      border: solid 0.2rem var(--bg-5) !important;
      font-size: 1.6rem;
      color: #565a6c !important;
      font-family: 'Baloo Da 2';
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: white !important;
      box-shadow: none !important;
      margin: 0;
      font-weight: normal;

      &:hover {
        background-color: var(--bg-5);
        color: #565a6c;
      }
    }
  }

  .upload-pic {
    font-family: 'Baloo Da 2';
    font-size: 1.4rem;
    color: var(--text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .img {
      width: 2.6rem;
      height: 2.6rem;
      margin-bottom: 0.5rem;
    }
  }

  .banner-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    border-radius: 10px;
    border: solid 2px #e1e2ea;

    .img-cont {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 10px;
    }
  }

  .heading-1 {
    font-family: 'Baloo Da 2';
    font-size: 1.34rem;
    font-weight: 500;
    margin-top: 2.9rem;
  }

  .about-organization {
    label {
      font-family: 'Baloo Da 2';
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #424557;
    }

    .form-control {
      height: 48px;
      border-radius: 10px;
      border: solid 2px #e1e2ea;
    }

    .part-1-org {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include breakpoint(mobileonly) {
        flex-direction: column;
      }

      .org-part-1-1 {
        width: 28%;

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        .organization-logo {
          width: 100%;
          height: 276px;
          border-radius: 10px;
          border: solid 2px #e1e2ea;
          font-family: 'Baloo Da 2';
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          @include breakpoint(mobileonly) {
            width: 100%;
          }

          .img-cont {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            position: relative;
            border-radius: 10px;
          }
        }

        .location-conts {
          justify-content: space-between;
          flex-direction: column;
          margin-top: 1rem;

          @include breakpoint(mobileonly) {
            flex-direction: column;
          }

          .map-container {
            width: 100%;
            height: 15rem;
            border-radius: 10px 10px 0 0;
            border: solid 2px #e1e2ea;

            @include breakpoint(mobileonly) {
              width: 100%;
            }
          }

          .location {
            width: 100%;
            font-size: 1.33rem;
            font-family: 'Baloo Da 2';
            border-radius: 10px;
            border: solid 2px #e1e2ea;
            padding: 2rem 1rem;
            color: var(--text-secondary);

            @include breakpoint(mobileonly) {
              width: 100%;
              margin-top: 2rem;
            }

            .form-control {
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      .form-container {
        width: 68%;
        padding: 0.5rem 0;

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        .main-heading {
          color: var(--text-secondary);
          font-size: 1.4rem;
          text-transform: uppercase;
          font-family: 'WickedGrit';
          margin-bottom: 1.5rem;
        }

        .description {
          font-size: 1.4rem;
          color: var(--text-secondary);
          font-family: 'Baloo Da 2';
          margin: 1rem 0;
        }

        .text-box {
          width: 100%;
          font-family: 'Baloo Da 2';
          padding: 1rem 0.5rem;
          font-size: 1.4rem;
          color: var(--text-secondary);
          min-height: 15rem;
          max-height: 15rem;
          border-radius: 10px;
          border: solid 2px #e1e2ea;
          outline: none;

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
            background-color: #fbf8f6;
          }
        }

        .sub-heading-2 {
          font-size: 1.4rem;
          color: var(--text-secondary);
          font-family: 'Baloo Da 2';
          font-weight: bold;
        }

        .get-address-btn {
          height: 4rem;
          width: 12rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3rem;
          border: 0.3rem solid var(--bg-1);
          color: var(--text-secondary);
          margin-left: 1rem;
          padding: 1rem;
          border-radius: 2rem;
          cursor: pointer;
          &:hover {
            background-color: var(--bg-1);
          }
        }

        .get-address-btn-disable {
          height: 4rem;
          width: 10rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3rem;
          border: 0.3rem solid var(--bg-1);
          color: var(--text-secondary);
          margin-left: 1rem;
          padding: 1rem;
          border-radius: 2rem;
          cursor: not-allowed;
        }
      }
    }

    .part-3 {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: none;

      .badge-section {
        display: flex;
        flex-wrap: wrap;

        .service-badge {
          font-size: 1.33rem;
          font-family: 'Baloo Da 2';
          display: table;
          line-height: 1.7rem;
          padding: 0.5rem 1.2rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Baloo Da 2';
          width: max-content;
          margin-right: 0.8rem;
          height: 24px;
          border-radius: 6px;
          border: solid 1px #c5c7d3;
          background-color: white;
          color: var(--text-primary);

          @include breakpoint(mobileonly) {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
}

.btn-container {
  display: flex;
  align-items: center;
  padding-bottom: 3rem;

  .edit-btn {
    height: 3.33rem;
    width: 10rem;
    border-radius: 0.4rem;
    border: solid 0.2rem var(--bg-5) !important;
    font-size: 1.6rem;
    color: #565a6c !important;
    font-family: 'Baloo Da 2';
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white !important;
    box-shadow: none !important;
    margin: 0;
    font-weight: normal;

    &:hover {
      background-color: var(--bg-5) !important;
      color: #565a6c !important;
    }
  }
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);

  span {
    margin-left: 0.5rem;
    font-family: 'Baloo Da 2';
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 500;
    margin-top: 1rem;
  }

  .img {
    width: 2.6rem;
    height: 2.6rem;

    path {
      fill: #ffffff;
    }
  }
}

.img-cont:hover .image {
  opacity: 0.3;
}

.img-cont:hover .middle {
  opacity: 1;
}

.text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.disable-edit {
  border: none !important;
  box-shadow: none !important;
  background-color: white !important;
  padding-left: 0 !important;
}

.tab-content {
  margin-top: 2rem;
}

.nav-link {
  padding: 1rem 1rem !important;
  font-size: 1.2rem;
}

.social-media-cont {
  padding-top: 1rem;
  border-top: 0.2rem solid var(--bg-1);
  margin-top: 2rem;
}
