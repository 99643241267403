.read-more-button{
  color: var(--highlight-primary);
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 0.2rem;
}

.displayed-text {
  font-size: 1.2rem;
  font-family: "Baloo Da 2";
  color: var(--text-secondary);
  white-space: pre-wrap;
}