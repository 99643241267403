@import "../../../../sass/abstracts/mixins";

.modal-open .modal {
  overflow-y: hidden !important;
}

.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .modal-content {
    width: 86.6rem;
    height: 50rem;
    opacity: 0.97;
    background-color: #697084;

    @include breakpoint(mobileonly) {
      width: 100%;
    }

    @include breakpoint(phablet) {
      width: 100%;
    }
  }

  .modal-header {
    border-bottom: none;
    border-bottom: none;
    display: flex;
    justify-content: flex-start;
    padding: 1rem 1.4rem;

    .modal-title {
      width: 100%;
      font-family: "WickedGrit";
      font-size: 1.6rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #14e528 !important;
    }
  }

  .modal-body {
    width: 100%;
    height: 190rem;
    font-family: "Baloo Da 2";
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    overflow: auto;
    padding: 1rem 3rem;

    .tandc-heading {
      font-weight: bold;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: #ccc;
    }
  }

  .modal-footer {
    width: 100%;
    height: 10rem;
    background-color: #697084;
    border-top: none;
    padding: 2rem;

    @include breakpoint(mobileonly) {
      display: flex;
      justify-content: center;
    }

    @include breakpoint(phablet) {
      display: flex;
      justify-content: center;
    }

    .donate-btn {
      width: 22rem;
      display: flex;
      justify-content: space-between;

      @include breakpoint(mobileonly) {
        width: 100%;
        justify-content: space-around;
      }

      @include breakpoint(phablet) {
        width: 100%;
        justify-content: space-around;
      }

      .rejectbtn {
        width: 10rem;
        height: 3rem;
        border-radius: .3rem;
        border: solid 0.7px #fc4ae8;
        background-color: #ffffff;
        color: #fc4ae8;
        font-family: "Baloo Da 2";
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .acceptbtn {
        width: 10rem;
        height: 3rem;
        border-radius: .3rem;
        border: solid 0.7px #fc4ae8;
        background-color: #fc4ae8;
        color: #fff;
        font-family: "Baloo Da 2";
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
