@import "../../../../sass/abstracts/mixins";

.otp-container {
  padding: 2rem 8rem;

  @include breakpoint(mobileonly) {
    padding: 2rem;
  }

  @include breakpoint(phablet) {
    padding: 2rem;
  }

  .title {
    font-family: "WickedGrit";
    font-size: 14px;
    color: var(--text-secondary);
  }

  .sub-title {
    opacity: 0.8;
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    color: var(--text-secondary);
    width: 65%;
  }

  .otp-error-message {
    margin-top: 1rem;
    opacity: 0.8;
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    color: var(--light-magenta);
  }

  .otp-input-container {
    margin-top: 1rem;

    .code-wrapper {
      width: 80%;
      justify-content: space-around;

      @include breakpoint(mobileonly) {
        width: 95%;
      }

      @include breakpoint(phablet) {
        width: 95%;
      }

      .code {
        width: 4rem;
        height: 4rem;
        opacity: 0.5;
        border-radius: 0.3rem;
        border: solid 0.06rem var(--text-secondary);
        background-color: #ffffff;
        font-size: 1.6rem;
      }

      .code.focus {
        border: solid 0.2rem var(--text-primary);
        box-shadow: 0 0.1rem 0.5rem 0 rgba(179, 179, 179, 0.5);
      }

      .code:not(:last-child) {
        margin-right: 1rem;

        @include breakpoint(mobileonly) {
          margin-right: 0.2rem;
        }

        @include breakpoint(phablet) {
          margin-right: 0.2rem;
        }
      }
    }
  }

  .btn-container {
    display: flex;
    .btn {
      width: 10rem;
      font-family: "Baloo Da 2";
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: bold;
    }
  }

  .login-cont {
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    color: var(--text-secondary);
    font-weight: bold;

    span {
      color: #6583d9;
      cursor: pointer;
    }

    .disable-resend {
      color: var(--bg-3) !important;
    }
  }
}
