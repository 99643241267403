@import "../../sass/abstracts/mixins";

.about-us-cont {
  padding-top: 8rem;
  display: flex;
  justify-content: center;
  @include largeScreen(){
    justify-content: flex-start;
  };

  .about-us-header {
    width: 85%;
    @include largeScreen(){
      width: 100%;
    };
    .title {
      font-family: "WickedGrit";
      font-size: 4rem;
      color: var(--light-magenta);
    }

    .about-header {
      font-family: "Baloo Da 2";
      font-size: 2rem;
      color: var(--text-secondary);
    }

    .block-cont {
      display: flex;
      justify-content: space-between;
      margin: 3rem 0;

      @include breakpoint(mobileonly) {
        flex-direction: column;
      }

      @include breakpoint(phablet) {
        flex-direction: column;
      }

      .block {
        width: 45%;

        .linksss {
          color: var(--highlight-primary) !important;
          margin-left: 0.5rem;
        }

        @include breakpoint(mobileonly) {
          width: 100%;
          margin-top: 2rem;
        }

        @include breakpoint(phablet) {
          width: 100%;
        }

        .content {
          font-family: "Baloo Da 2";
          font-size: 1.4rem;
          color: #697084;
          padding: 1rem 0;
          width: 45rem;

          span {
            color: var(--text-primary);
            font-weight: bold;
          }

          @include breakpoint(mobileonly) {
            width: 100%;
          }
        }

        .social-links {
          display: flex;
          align-items: center;
          margin-top: 2rem;
          .social-text {
            font-family: "Baloo Da 2";
            font-size: 1.4rem;
            margin-right: 1rem;
            color: #697084;
          }
          .icon-cont {
            height: 2rem;
            width: 2rem;
            border-radius: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0.6rem;
            cursor: pointer;
            background-color: var(--light-magenta);
          }

          i {
            font-size: 1.4rem;
            color: white;
          }
        }
      }
    }
  }
}
