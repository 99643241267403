.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockMsg {
  border: none !important;
  background-color: transparent !important;
}

.blockingMessage {
  position: relative;
  z-index: 0;
  width: 12rem;
  height: 12rem;
  border-radius: 10rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .processing-msg {
    color: white;
    font-family: "Baloo Da 2";
    font-size: 1.2rem;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -40%;
    top: -40%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 50%, 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(var(--light-magenta), var(--light-magenta)),
      linear-gradient(var(--vivid-green), var(--vivid-green)), linear-gradient(var(--light-magenta), var(--light-magenta)),
      linear-gradient(var(--vivid-green), var(--vivid-green));
    -webkit-animation: rotate 6s linear infinite;
    -moz-animation: rotate 6s linear infinite;
    animation: rotate 6s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: .4rem;
    top: .4rem;
    bottom: .4rem;
    right: .4rem;
    background: var(--text-secondary);
    border-radius: 100px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(1turn);
  }
}
