.register-initial-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .part {
    box-shadow: 0 0 4px 4px var(--bg-6);
    padding: 2rem;
    cursor: pointer;
    background-color: var(--bg-6);
    margin: 0 4rem;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }

  .img {
    height: 30rem;
  }

  .textt {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    text-align: center;
    font-family: "Baloo Da 2";
  }
}


.capture-email{

  .modal-header{
    display: flex;
    align-items: center;
  }

  .modal-content{
    height: 23rem !important;
    width: 40rem !important;
  }

  .capture-email-close{
    font-size: 2rem;
    color: var(--bg-2);
  }

  .capture-email-wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;


    &__input{
      border: .2rem solid var(--bg-5);
      color: var(--text-primary);
      width: 100%;
      box-shadow: none;
      border-radius: .2rem;
      padding: 1rem;
      position: relative;
      font-size: 1.8rem;
      margin-bottom: 1rem;

      &:active,&:focus{
        outline: none;
        box-shadow: none;
      }
    }

    p{
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    &__btn{
      width: 100%;
      height: 3.33rem;
    }
  }
}
