@import "../../sass/abstracts/mixins";
.not-found{
  padding-top: 11rem;
  @include largeScreen();
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "WickedGrit", sans-serif;
  padding-bottom: 6.4rem;
  &__icon-wrapper{

  }

  h3{
    font-size: 4rem;
    margin: 2rem;
  }

  &__link{
    a{
      color: var(--highlight-primary) !important;
    }
  }


  .not-found-icon{
    border: .3rem solid var(--text-secondary);
    padding: .8rem 1.2rem;
    width: 13rem;
    height: 9rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__dots{
      display: block;
      position: absolute;
      top: .225rem;
      left: .225rem;
      width: .5rem;
      height: .5rem;
      border-radius: 50%;
      background: var(--text-secondary);

      &:after{
        content: "";
        display: block;
        position: absolute;
        width: .5rem;
        height: .5rem;
        border-radius: 100%;
        background: var(--text-secondary);
        left: 1.5rem;
      }

      &:before{
        content: "";
        display: block;
        position: absolute;
        width: .5rem;
        height: .5rem;
        border-radius: 100%;
        background: var(--text-secondary);
        left: .75rem;
      }
    }

    &__404{
     font-size: 1.8rem;
    }
  }
}