.AV {
  .AV-close {
    font-size: 2rem;
    color: var(--bg-3);
    cursor: pointer;
  }
  
  .modal-content {
    height: 46rem !important;
    padding: 1rem;
    width: 58rem !important;
  }

  .modal-body{
    padding: 1rem !important;
  }


  .AV-wrapper {
    &__input {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      &--label {
        font-size: 1.6rem;
      }
      &--input {
        border: .2rem solid var(--bg-5);
        color: var(--text-primary);
        width: 100%;
        box-shadow: none;
        border-radius: .2rem;
        padding: .4rem 1rem;
        position: relative;
        font-size: 1.8rem;
        margin-bottom: 1rem;

        &:active,&:focus{
          outline: none;
          box-shadow: none;
        }
      }

      &--helper-text{
        font-size: 1.2rem;
      }
    }
  }

  &__btn {
    margin-top: 1rem;
    width: 12rem;
  }
}
