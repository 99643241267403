@import '../../../sass/abstracts/mixins';

.wishlist-expand-cont {
  padding-top: 6.66rem;
  background-color: #ffffff;

  @include largeScreen();

  .banner-part {
    height: calc(100vh - 35rem);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0 0 8rem 0;

    @include breakpoint(mobileonly) {
      height: 19rem;
    }
  }

  .back-cont {
    width: 100%;
    padding: 1rem 13% 0 14%;
  }

  .wishlist-main-cont-block {
    display: flex;
    justify-content: center;
    .wishlist-cont {
      width: 93%;
      padding: 2.5rem 0 3rem 0;
      display: flex;
      font-family: 'Baloo Da 2';
      justify-content: space-between;

      @include largeScreen() {
        width: 100%;
      }

      @include breakpoint(mobileonly) {
        padding: 1rem;
        width: 100%;
        flex-direction: column;
      }

      .expand-part-1 {
        width: 68%;

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        .text-content {
          width: 100%;

          .main-heading {
            font-size: 3rem;
            font-weight: 600;
            color: var(--highlight-primary);

            @include breakpoint(mobileonly) {
              font-size: 2rem;
            }

            @include breakpoint(phablet) {
              font-size: 2rem;
            }
          }

          .from {
            font-size: 1.4rem;
            span {
              font-weight: 600;
              margin-left: 0.4rem;
            }
          }

          .created-on {
            font-family: 'Baloo Da 2';
            color: #75798a;
            font-size: 1.3rem;
            margin: 1.8rem 0;
          }
        }

        .wishlist-itmes-cont {
          width: 100%;

          .wishlist-itmes-cont-wrapper {
            min-height: 15rem;
            overflow-y: auto;
            padding-right: 2rem;
            margin-top: 2rem;
            max-height: 50rem;

            @include breakpoint(mobileonly) {
              padding-right: 0;
            }

            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 6px;
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
              background-color: #323649;
            }
          }
          .heading {
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--text-primary);
            margin-bottom: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2rem 0;

            .type-image {
              height: 4rem;

              @include breakpoint(mobileonly) {
                height: 3rem;
              }
            }
          }
          .sub-heading {
            color: var(--text-secondary);
            font-size: 1.2rem;

            .anchor-tag {
              color: var(--light-magenta) !important;
              text-decoration: underline !important;
              cursor: pointer;
            }
          }

          .item {
            display: flex;
            font-size: 1.4rem;
            margin-bottom: 2rem;
            color: var(--text-secondary);
            font-family: 'Baloo Da 2';
            justify-content: space-between;
            align-items: center;

            @include breakpoint(mobileonly) {
              // flex-direction: column;
              align-items: flex-start;
              font-size: 1.6rem;
            }

            .logo {
              width: 10%;

              @include breakpoint(mobileonly) {
                width: 4rem;
                height: auto;
                margin-bottom: 1rem;
              }

              .image {
                width: 100%;
                height: 100%;
              }
            }

            .discription-donation {
              width: 60%;

              @include breakpoint(mobileonly) {
                width: 30%;
              }

              .description {
                width: 27.9rem;
                font-size: 1.3rem;
                font-weight: 600;
                font-family: 'Baloo Da 2';
                color: var(--text-primary);

                @include breakpoint(mobileonly) {
                  margin-bottom: 1rem;
                  width: 100%;
                  font-size: 1rem;
                }
              }

              .donations {
                display: flex;
                font-size: 1.3rem;
                font-family: 'Baloo Da 2';
                color: #697084;

                @include breakpoint(mobileonly) {
                  font-size: 1rem;
                }

                .needed {
                  margin-right: 2.75rem;
                }
                .donated {
                  margin-right: 2.75rem;
                }
                .remaining {
                  margin-right: 2.75rem;
                }
              }
            }

            .price {
              width: 15%;

              @include breakpoint(mobileonly) {
                width: 100%;
                margin-bottom: 1rem;
              }
            }

            .donate-btn {
              width: 6rem;
              height: auto;
              background-color: #52f488 !important;
              border: none;
              box-shadow: none !important;
              outline: none !important;
              border: none !important;

              @include breakpoint(mobileonly) {
                margin: 1rem 0.5rem;
              }
            }
          }
        }
      }

      .expand-part-2 {
        width: 30%;
        font-family: 'Baloo Da 2';

        @include breakpoint(mobileonly) {
          width: 100%;
          margin-bottom: 3rem;
          padding: 2rem 0;
          border-bottom: solid 2px #e1e2ea;
        }

        @include breakpoint(phablet) {
          width: 100%;
          padding: 2rem;
          margin-left: 0;
          height: 100%;
        }

        .right-logo-part {
          text-align: center;
          width: 100%;

          .org-wrapper {
            display: flex;
            border-top: 0.2rem solid var(--bg-3);
            border-bottom: 0.2rem solid var(--bg-3);
            padding: 1.2rem 0;

            img {
              width: 5rem;
              height: 5rem;
            }

            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              margin-left: 2rem;
            }

            .name {
              font-size: 1.3rem;
              font-weight: 600;
              font-family: 'Baloo Da 2';
              color: var(--text-primary);
              text-align: left !important;

              &:hover {
                color: var(--highlight-primary) !important;
              }
            }

            .address {
              font-size: 1rem;
              color: #75798a;
              text-align: left;
            }
          }

          .image-part {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-bottom: 5rem;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 15rem;

            .rightlogo {
              height: 10rem;
              width: 10rem;
              border-radius: 4px;
              background-color: #d8d8d8;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              bottom: -3rem;
              left: 0.5rem;
              box-shadow: 2px 2px 0.5rem 0.1rem rgba(0, 0, 0, 0.5);
            }
          }
          .content-part {
            font-family: 'Baloo Da 2';
            margin-top: 1rem;
            font-size: 1.2rem;
            margin-bottom: 2rem;
            white-space: pre-wrap;
            text-align: left;
          }
        }

        .social-heading {
          font-size: 1.2rem;
          font-weight: 600;
          color: var(--text-primary);
          margin-top: 2rem;
          text-align: center;
        }

        .sharelist {
          justify-content: center;
        }

        .icon-main-container {
          display: flex;
          justify-content: center;

          .icon-cont {
            width: 6rem;
            justify-content: space-between;
            display: flex;
            margin-top: 2rem;

            i {
              font-size: 2.4rem;
              cursor: pointer;

              &:hover {
                color: var(--light-magenta);
              }
            }
          }
        }
      }
    }
  }

  .social-media-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .social-heading {
      margin-top: 0 !important;
    }

    .sharelist {
      margin-left: 1rem;
    }
  }

  .love2shop-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    flex-direction: column;
    width: 100%;

    @include breakpoint(mobileonly) {
      flex-direction: column;
      align-items: center;
    }

    .css-26l3qy-menu {
      z-index: 9 !important;
    }

    &__progress {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 100%;
    }

    &__progress-details {
      margin-left: 2rem;
      align-self: center;

      p {
        font-size: 1.4rem;
        color: var(--text-ternary);
      }

      span {
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--text-secondary);
      }
    }

    &__remaining {
      margin-bottom: 1rem;
      span {
        color: var(--text-primary);
        font-size: 2rem;
        line-height: 0.8;
      }
      div {
        font-size: 1.4rem;
        color: var(--text-ternary);
      }
    }

    &__progress-details-wrapper {
      display: flex;
      align-items: center;
      border-radius: 0.6rem;
      width: 100%;
      justify-content: flex-start;
    }
  }

  .love2shop {
    width: 80%;
    border-radius: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include breakpoint(mobileonly) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &__label {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .select-wrapper {
      width: 30rem;
    }

    &__btn {
      width: 30rem;
      height: 3.33rem;
      margin-top: 2rem;

      @include breakpoint(mobileonly) {
        margin: 2rem 0rem 0;
        width: 100%;
      }
    }

    &__inputs {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      font-size: 1.6rem;
    }

    .note {
      font-size: 1.1rem;
      margin: 1rem 0;
    }

    &__plus {
      height: 5rem;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-primary);
      border: 0.2rem solid var(--bg-5);
      border-radius: 0.4rem;
      cursor: pointer;
      margin-left: 0.5rem;

      &:hover {
        background-color: var(--text-ternary);
      }
    }

    &__show {
      position: relative;
      border: 0.3rem solid var(--bg-4);
      width: 19rem;
      font-size: 2.8rem;
      font-weight: 700;
      color: var(--text-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.3rem;
    }

    &__minus {
      height: 5rem;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--text-primary);
      border: 0.2rem solid var(--bg-5);
      border-radius: 0.4rem;
      cursor: pointer;
      margin-right: 0.5rem;

      &:hover {
        background-color: var(--text-ternary);
      }
    }

    &__contribute {
      margin-top: 2rem;

      &--label {
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 1rem;
        color: var(--text-secondary);
      }

      &--input-wrapper {
        position: relative;
      }

      &--symbol {
        color: var(--text-primary);
        position: absolute;
        top: 50%;
        left: 3%;
        font-size: 1.8rem;
        z-index: 1;
        transform: translate(-50%, -50%);
      }

      &--input {
        border: 0.2rem solid var(--bg-5);
        color: var(--text-primary);
        width: 30rem;
        box-shadow: none;
        border-radius: 0.2rem;
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        position: relative;
        font-size: 1.6rem;

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        @include breakpoint(mobileonly) {
          width: 100%;
        }
      }

      &--total {
        text-align: center;
        margin-top: 2rem;
        font-size: 1.4rem;
        color: var(--text-primary);
        font-weight: 600;
        width: 30rem;
      }
    }
  }
}

.donation-success {
  .modal-content {
    height: 30rem !important;
    width: 60rem !important;

    @include breakpoint(mobileonly) {
      height: 30rem !important;
      width: 35rem !important;
    }
  }

  .donation-success-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-size: 4rem;
    margin-bottom: 2rem;
    @include breakpoint(mobileonly) {
      font-size: 2rem;
    }
  }

  &__amount-wrapper {
    display: flex;
    justify-content: center;
  }

  &__amount {
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    border: 0.4rem solid var(--vivid-green);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: var(--vivid-green);

    @include breakpoint(mobileonly) {
      font-size: 2rem;
    }
  }

  .donation-success-close {
    font-size: 1.6rem;
    color: var(--bg-3);
  }
}
