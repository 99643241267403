@import '../../sass/abstracts/mixins';
.discovermore-container {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  .search-input-part {
    position: relative;
    display: flex;

    .search-icon {
      position: absolute;
      top: 1.5rem;
      margin-left: 4.4rem;
      font-size: 3rem;
      color: var(--text-secondary);

      @include breakpoint(mobileonly) {
        margin-left: 1.4rem;
      }
    }

    .search-placeholder {
      height: 6.6rem;
      background-color: #f6f6f6;
      padding: 0 8rem;
      font-family: 'Baloo Da 2';
      font-size: 3rem !important;
      border-radius: 0.6rem;
      box-shadow: none !important;
      outline: none !important;
      border-color: #e1e2ea !important;
      border-radius: 0 !important;

      @include breakpoint(mobileonly) {
        padding: 0 5rem;
      }
    }

    .close-icon {
      position: absolute;
      top: 2.5rem;
      color: var(--text-secondary);
      right: 3rem;
      cursor: pointer;
    }
  }

  .search-results-part {
    display: flex;
    justify-content: space-between;
    padding: 2rem 5rem;

    @include breakpoint(mobileonly) {
      flex-direction: column;
      padding: 3rem;
    }

    .heading {
      font-size: 1.3rem;
      font-weight: 600;
      font-family: 'Baloo Da 2';
      color: var(--text-secondary);

      @include breakpoint(mobileonly) {
        margin-top: 3rem;
      }
    }

    .service-goals-part {
      width: 30%;

      @include breakpoint(mobileonly) {
        width: 100%;
      }

      .heading-1 {
        font-size: 1.3rem;
        font-weight: 600;
        font-family: 'Baloo Da 2';
        color: var(--text-secondary);
      }

      .service-goals-results {
        display: flex;
        margin-top: 1.3rem;
        height: 25rem;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
          background-color: #323649;
        }

        @include breakpoint(mobileonly) {
          display: block;
        }
        .part-1 {
          font-size: 1.3rem;
          color: var(--text-secondary);
          font-family: 'Baloo Da 2';
          line-height: 2.5rem;

          .item {
            cursor: pointer;
            &:hover {
              color: var(--highlight-primary) !important;
            }
          }
        }
      }
    }

    .campaigns-part {
      width: 65%;
      @include breakpoint(mobileonly) {
        width: 100%;
      }

      .cards-camp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .no-data-cont {
          height: 26rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            height: 11rem;
          }
        }

        .progress {
          height: 0.5rem;
          width: 18rem;
          margin: 0.5rem 0;
        }

        .campaigns {
          width: 50%;
          display: flex;
          align-items: center;
          margin: 1rem 0;

          .image-part {
            margin-right: 1.6rem;
            .image {
              height: 5.6rem;
              width: 5.6rem;
            }
          }
          .campaigns-details {
            .campaigns-name {
              font-size: 1.3rem;
              font-weight: 600;
              font-family: 'Baloo Da 2';
              color: var(--text-secondary);
              padding: 0;
              width: 14rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .content {
              font-size: 1.3rem;
              font-family: 'Baloo Da 2';
              color: var(--text-secondary);
              width: 73%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .campaigns:hover {
          .campaigns-details {
            .campaigns-name {
              color: var(--highlight-primary);
            }
          }
        }
      }
    }
  }
}
