@import '../../../sass/abstracts/mixins';

.admin {
  padding-top: 6.66rem;
  display: flex;
  height: 100vh;
  font-family: 'Baloo Da 2', 'sans-serif';

  @include largeScreen();

  .admin-sidebar {
    padding: 2rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(179, 179, 179, 0.5);
    position: fixed;
    height: 100%;
    background: var(--text-primary);
    width: 20rem;

    @include breakpoint(mobileonly) {
      display: none;
    }

    &__item {
      font-size: 1.4rem;
      padding: 1rem 0;
      cursor: pointer;
      color: var(--bg-6) !important;

      &:hover {
        a {
          color: var(--highlight-primary) !important;
        }
      }

      &--active {
        color: var(--highlight-primary) !important;
        font-weight: 600;
      }
    }
  }

  .admin-content {
    margin-left: 16.2%;
    width: 100%;

    @include breakpoint(mobileonly) {
      margin-left: 0;
    }
  }
}
