.organisatoins-cont {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  font-family: 'Baloo Da 2';
  flex-direction: column;
  align-items: center;

  .manage-heading {
    font-size: 2rem;
    margin: 1rem 0;
    width: 94%;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;

    .select-wrapper {
      width: 25rem;
    }
  }

  .org-cont {
    width: 95%;

    tr {
      height: 5rem;
    }

    th {
      border: none !important;
      font-size: 1.4rem;
      color: var(--text-secondary);
    }

    th:nth-child(2) {
      width: 25%;
    }

    // th:nth-child(3) {
    //   width: 35%;
    // }

    td {
      border: none !important;
      font-size: 1.3rem;
      height: 5rem;
      color: var(--text-secondary);
    }

    .icon-cont {
      .icon {
        font-size: 2rem;
        cursor: pointer;
        color: var(--text-secondary);
      }

      .icon-active {
        font-size: 2rem;
        cursor: pointer;
        color: var(--highlight-primary);
      }
    }

    .orga-cont {
      .text-1 {
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: var(--highlight-primary) !important;
        }
      }

      .dis {
        font-weight: normal;
        cursor: not-allowed;
      }
      
    }

    .center-cont {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
    }

    .status-cont {
      .ur {
        color: #eea700;
        margin-right: 0.5rem;
      }

      .ap {
        color: var(--vivid-green);
        margin-right: 0.5rem;
      }

      .ac {
        color: #ff5b5b;
        margin-right: 0.5rem;
      }
    }

    .table-cont {
      height: calc(100vh - 15rem);
      overflow: auto;
      padding-right: 1rem;

      &::-webkit-scrollbar {
        width: 8px;
        height: 9px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background-color: #323649;
      }
    }

    .table-dropdown-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .table-dropdown {
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      border: 0.1rem solid #e1e2ea;
      border-radius: 0.4rem;
      background-color: #ffffff;
      cursor: pointer;
    }

    .o-dropdown {
      position: absolute;
      top: 4rem;
      right: .4rem;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(31, 34, 51, 0.12);
      border: solid 1px #c5c7d3;
      background-color: #ffffff;
      z-index: 2;
      min-width: 15rem;

      &__item {
        padding: 1rem 2rem;

        &:hover {
          cursor: pointer;
          background-color: #dadada;
        }
      }
    }
  }

  .no-result-found {
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    color: var(--text-secondary);
    padding-top: 5rem;
  }
}
