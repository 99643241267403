@import '../base/fonts';

.header-cont {
  height: 6.66rem;
  box-shadow: 0 2px 4px 0 rgba(31, 34, 51, 0.08), inset 0 -1px 0 0 #e1e2ea;
  background-color: #ffffff;
  background-color: var(--bg-2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  font-family: 'Athiti-SemiBold';
  z-index: 99;

  .navbar-nav {
    align-items: center;

    @include breakpoint(mobileonly) {
      align-items: flex-start;
    }
  }

  .my-dropdown {
    position: relative;
    margin-right: 4rem;

    .icon {
      position: absolute;
      right: 1.5rem;
      font-size: 3rem;
      top: -2rem;
      color: var(--bg-4);

      @include breakpoint(mobileonly) {
        right: 19.5rem;
      }

      @include breakpoint(mobileonly) {
        right: 19.5rem;
      }
    }
  }

  .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    letter-spacing: 1px;
    font-size: 1.8rem;
    text-transform: uppercase;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: solid 0.5px var(--bg-4);
    background-color: var(--bg-4);
  }

  .dropdown-menu {
    position: absolute;
    left: -18rem;
    top: 5rem;
    width: 22.4rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;

    @include breakpoint(mobileonly) {
      left: 0;
    }

    @include breakpoint(phablet) {
      left: 0;
    }
  }

  .dropdown-item {
    padding: 0 2rem;

    &:hover {
      background-color: #9fffd5;
    }
  }

  .profile-name-cont {
    height: 5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .profile-name {
      font-size: 16px;
      font-weight: 600;
      color: #697084;
    }

    .email {
      color: var(--text-four);
    }

    .charity {
      color: var(--text-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .manage {
      font-family: 'Baloo Da 2';
      font-size: 10px;
      color: #6583d9;
    }
  }

  .organization-name {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    white-space: normal;
    width: 100%;

    .name {
      font-family: 'Baloo Da 2';
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--text-primary) !important;
      width: 100%;
    }

    i {
      margin-left: 1rem;
    }
  }

  .logout-cont {
    height: 4rem;
    display: flex;
    align-items: center;
    .name {
      font-size: 1.2rem;
      font-weight: 600;
      color: #697084;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .navbar {
    color: #565a6c !important;
    font-weight: 600;
    font-size: 1.33rem;
    width: 95%;
    font-family: 'Baloo Da 2';
    @include largeScreen();


    @include breakpoint(mobileonly) {
      width: 100%;
      padding: 0 2rem 0 1rem;
    }

    @include breakpoint(phablet) {
      width: 100%;
      padding: 0 2rem;
    }

    .links {
      margin: 0 1.33rem;
      font-size: 1.33rem;

      @include breakpoint(mobileonly) {
        margin: 0 0 1.5rem 0;
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
      }

      @include breakpoint(phablet) {
        margin: 0 0 1.5rem 0;
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
      }
    }

    .active-link {
      color: var(--highlight-primary) !important;
      position: relative;
    }

    .active-link-drops {
      color: var(--highlight-primary) !important;
      position: relative;

      // &:before {
      //   content: '\A';
      //   border-style: solid;
      //   border-width: 10px 10px 10px 0;
      //   border-color: transparent var(--bg-1) transparent transparent;
      //   position: absolute;
      //   left: 3rem;
      //   top: 3rem;
      //   transform: rotate(90deg);
      //   z-index: 99999999;
      // }
    }

    .search-login-cont {
      @include breakpoint(mobileonly) {
        width: 100%;
      }

      @include breakpoint(phablet) {
        width: 100%;
      }
    }

    .search {
      cursor: pointer;
      i {
        margin-right: 0.5rem;
      }
      &:hover {
        color: var(--highlight-primary);
      }
    }

    .discover-more-links {
      margin: 0 0 0 1.2rem;
      cursor: pointer;

      @include breakpoint(mobileonly) {
        margin: 1.8rem 1.8rem 0 0.5rem;
      }

      i {
        margin: 0 0.5rem;
      }
    }

    .form-container {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin: 0 1rem;

      @include breakpoint(mobileonly) {
        margin-left: 0;
        width: 100%;
      }

      @include breakpoint(phablet) {
        margin-left: 0;
        width: 100%;
      }

      .input-field {
        width: 7rem;
        height: 3.33rem;
        border-radius: 2.2rem;
        outline: none;
        box-shadow: none;
        border: none;
        padding: 0 0 0 3rem;
        font-size: 1.33rem !important;
        transition: width 0.4s linear;
        cursor: pointer;
        color: #565a6c !important;
        font-weight: 600;

        &:focus,
        &:active {
          transition: 0.2s all;
        }

        @include breakpoint(mobileonly) {
          width: 100%;
          border-radius: 0.3rem;
          margin: 0;
        }

        @include breakpoint(phablet) {
          width: 100%;
          border-radius: 0.3rem;
          margin: 0;
        }
      }

      .active-input {
        width: 40rem;
        box-shadow: 0 0 0 0.16rem #e1e2ea !important;
        @include breakpoint(mobileonly) {
          width: 100%;
        }

        @include breakpoint(phablet) {
          width: 100%;
        }
      }

      .load-container {
        position: absolute;
        left: 1rem;
        font-size: 1.8rem;
      }

      .search-icon {
        position: absolute;
        left: 1rem;
        font-size: 1.5rem !important;
        top: 25%;
      }

      .close {
        position: absolute;
        right: 1.2rem;
        font-size: 1.6rem;
        cursor: pointer;
        color: var(--text-primary);
      }

      .search-icon-active {
        color: var(--text-secondary);
      }
    }

    .search-items {
      position: absolute;
      width: 100%;
      top: 5rem;
      z-index: 9;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      left: 0;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background-color: #323649;
      }

      .search-links {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        cursor: pointer;
        padding-bottom: 0.4rem;
        border-bottom: 0.1rem solid var(--bg-3);
        width: 100%;
        display: flex;
        flex-direction: column;

        &:hover {
          color: var(--highlight-primary) !important;
        }

        .text-big {
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .text-small {
          font-size: 1rem;
          color: var(--text-secondary);
          opacity: 0.5;
        }
      }

      .no-result-found-cont {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
          height: 10rem;
        }
      }

      .no-data {
        height: 7rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        color: var(--text-ternary);
      }
    }

    .adminOptions {
      cursor: pointer;
      position: relative;
      width: 8rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      margin-right: 1rem;
    }

    .activeAdminOption {
      border-radius: 6px;
      border: solid 1px #e1e2ea;
      background-color: #ffffff;
    }

    .adminItems {
      position: absolute;
      min-height: 3rem;
      max-height: 25rem;
      overflow: auto;
      width: 18rem;
      top: 103%;
      background-color: #fff;
      right: 0;
      flex-direction: column;
      display: flex;
      padding: 1rem;
      z-index: 10;
      border: solid 1px #e1e2ea;

      .loc-item {
        margin-bottom: 0.5rem;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: var(--highlight-primary) !important;
        }
      }

      .loc-item-active {
        color: var(--highlight-primary) !important;
      }
    }

    .activeLocationLinks {
      border-radius: 6px;
      border: solid 1px #e1e2ea;
      background-color: #ffffff;
      width: 11rem !important;
      margin-right: 1.5rem;
    }

    .location-links {
      cursor: pointer;
      position: relative;
      width: auto;
      height: 3rem;

      @include breakpoint(mobileonly) {
        margin: 1rem 0.8rem;
      }

      .link-drops-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        width: 100%;

        .name {
          width: 90%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &::before {
          display: none !important;
        }
      }

      i {
        margin: 0 0.5rem;
      }
    }

    .location-items {
      position: absolute;
      min-height: 3rem;
      max-height: 25rem;
      overflow: auto;
      width: 100%;
      top: 103%;
      background-color: #fff;
      left: 0;
      flex-direction: column;
      display: flex;
      padding: 1rem;
      z-index: 10;
      border: solid 1px #e1e2ea;

      .loc-item {
        margin-bottom: 0.5rem;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: var(--highlight-primary);
        }
      }

      .loc-item-active {
        color: var(--highlight-primary) !important;
      }
    }

    .btn {
      background-color: white;
      color: var(--highlight-primary) !important;
      outline: none !important;
      border: 0.1rem solid var(--highlight-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.33rem;
      box-shadow: none !important;
      border-radius: 0.5rem !important;
      font-weight: 600;
      width: 10rem;
      height: 3.33rem;
      border-radius: 8.33rem !important;

      &:hover {
        background-color: var(--highlight-primary);
        color: white !important;
        transition: 0.2s all;
      }

      @include breakpoint(mobileonly) {
        width: 90%;
      }

      @include breakpoint(phablet) {
        width: 90%;
      }
    }

    .btn-active {
      background-color: var(--highlight-primary) !important;
      color: white !important;
    }
  }

  .discover-more-cont {
    position: absolute;
    top: 117%;
    background-color: #f6f6f6;
    width: 75%;
    overflow: auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .btn-login {
    width: 8rem !important;
  }

  .bar-icon-wrapper {
    i {
      font-size: 2rem;
      margin-right: 2rem;
    }
  }
}

.mobile-nav-bar {
  margin-left: auto;
  display: flex;
  align-items: center;

  .search {
    font-size: 1.8rem;
  }
}

.my-sidenav {
  width: 80%;
  height: 100%;
  z-index: 999 !important;
  position: fixed !important;

  @include breakpoint(small-laptop) {
    display: none !important;
  }

  @include breakpoint(laptop) {
    display: none !important;
  }

  @include breakpoint(desktop) {
    display: none !important;
  }
}

.mobile-side-bar {
  font-family: 'Baloo Da 2', 'sans-serif';

  .close-icon {
    font-size: 2rem;
    display: flex;
    justify-content: flex-end;
    color: var(--text-secondary);
    padding: 1rem 2rem;
  }

  .auth-wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;

    .btn-auth {
      background-color: #fff;
      outline: none !important;
      border: 0.1rem solid var(--highlight-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.33rem;
      box-shadow: none !important;
      font-weight: 600;
      width: 9rem;
      height: 3.33rem;
      border-radius: 6rem !important;
    }
  }

  .profile {
    display: flex;
    padding-bottom: 0.2rem;
    padding: 1rem;
    background-color: var(--text-primary);
    

    &__pic-wrapper {
    }

    &__pic {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background: var(--vivid-green);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      font-weight: 600;
    }

    &__details {
      margin-left: 1rem;

      div:first-child {
        color: var(--vivid-green);
        font-size: 1.4rem;
      }
      div {
        font-size: 1.1rem;
        font-weight: 600;
        color: white;
      }
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem 2rem;

    &__item {
      font-size: 1.4rem;
      font-weight: 400;
      margin: 1rem 0;

      &--active {
        color: var(--highlight-primary) !important;
        font-weight: 700;
      }
    }

    @include breakpoint(small-laptop) {
      display: none !important;
    }

    @include breakpoint(laptop) {
      display: none !important;
    }

    @include breakpoint(desktop) {
      display: none !important;
    }
  }
}

.mobile-search {
  height: 100%;
  position: fixed;
  z-index: 9999 !important;
  width: 100%;
  background: white;
  left: 0;
  bottom: 0;
  padding: 1rem 2rem;

  .input-field {
    box-shadow: 0 0 0 0.16rem #e1e2ea !important;
    border-radius: 0.6rem !important;
  }

  &__close {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  &__results {
    height: 100%;
    .v-search__body {
      padding: 0;
      padding-right: 1rem;
    }

    .rc {
      width: 100% !important;
      margin-right: 0 !important;
    }
    .rcs {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}
