@import '../../../sass/abstracts/mixins';
.home-container {
  padding-top: 6.66rem;
  background-color: var(--bg-4);
  @include largeScreen();

  .carousel {
    height: 100%;
    background-color: var(--bg-4);
    border-bottom-right-radius: 9.3rem;
  }

  .carousel-inner {
    background-color: var(--bg-4) !important;
  }

  .carousel-control-prev {
    height: 3rem;
    width: 3rem;
    background-color: black;
    border-radius: 50%;
    top: 40%;
    display: none;
  }

  .carousel-control-next {
    height: 3rem;
    width: 3rem;
    background-color: black;
    border-radius: 50%;
    top: 40%;
    display: none;
  }

  .carousel-indicators {
    top: 92% !important;

    @include breakpoint(mobileonly) {
      top: 100%;
    }

    @include breakpoint(phablet) {
      top: 100%;
    }
  }

  .carousel-indicators li {
    background-color: var(--light-magenta) !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
  }

  .banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 34rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 8.33rem;
    background-blend-mode: normal, color;

    @include breakpoint(mobileonly) {
      padding: 3rem 0;
      border-bottom-right-radius: 0;
    }

    .banner-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.7);
      height: 100%;
      width: 100%;
      padding: 5rem;
      border-bottom-right-radius: 100px;

      @include breakpoint(mobileonly) {
        height: 39rem;
        padding: 3rem;
        border-bottom-right-radius: 0;
      }

      .tag-cont {
        display: flex;

        @include breakpoint(mobileonly) {
          display: none;
        }

        .tag {
          height: 24px;
          border-radius: 6px;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 600;
          color: #eff0f5;
          font-family: 'Baloo Da 2';
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;
          padding: 0 1rem;
          margin-right: 1rem;
          border: 0.1rem solid white;
        }
      }
      .title {
        font-family: 'WickedGrit';
        font-size: 3rem;
        line-height: 1.14;
        color: #ffffff;
        margin-bottom: 1rem;
        letter-spacing: -0.8px;
        font-weight: normal;
        width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include breakpoint(mobileonly) {
          font-size: 2.4rem;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }
      }

      .description {
        font-family: 'Baloo Da 2';
        font-size: 1.5rem;
        color: var(--light-magenta);
        margin-bottom: 1rem;

        @include breakpoint(mobileonly) {
          font-size: 2rem;
          margin-bottom: 0;
        }

        @include breakpoint(phablet) {
          font-size: 2rem;
          margin-bottom: 0;
        }
      }

      .sub-description {
        font-family: 'Baloo Da 2';
        font-size: 1.3rem;
        width: 56.33rem;
        color: #ffffff;
        margin-bottom: 1rem;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0.5rem 0;

        @include breakpoint(mobileonly) {
          width: 100%;
          font-size: 1.3rem;
          margin-bottom: 0;
        }

        @include breakpoint(phablet) {
          width: 100%;
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }

      .btn {
        width: 10.25rem;
        font-weight: bold;
        font-size: 1.6rem;
        height: 3rem;
      }
    }
  }

  .featured-container {
    display: flex;
    background-color: white;
    padding: 2rem 7rem;
    border-top-left-radius: 8.3rem;

    @include breakpoint(mobileonly) {
      height: 100%;
      border-top-left-radius: 0;
      padding: 0;
      flex-direction: column;
    }

    @include breakpoint(phablet) {
      height: 100%;
      border-top-left-radius: 0;
      padding: 0;
      flex-direction: column;
    }

    .image-container {
      background-image: url('../../../images/Home/featured2.png');
      height: 15rem;
      width: 35%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 3rem;
      position: relative;
      border-radius: 0.5rem;

      @include breakpoint(mobileonly) {
        width: 100%;
        margin-top: 0;
      }

      @include breakpoint(phablet) {
        width: 100%;
        margin-top: 0;
      }

      .logo-cont {
        width: 10rem;
        height: 10rem;
        border-radius: 4px;
        background-color: #d8d8d8;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: -3rem;
        left: 0.5rem;
        // box-shadow: 2px 2px 0.3rem 0.1rem rgba(0, 0, 0, 0.5);
      }
    }

    .carousel-container {
      width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 5rem;

      @include breakpoint(phablet) {
        width: 100%;
        padding: 2rem 1.5rem 0 1.5rem;
        margin-left: 0;
        margin-top: 2rem;
      }

      @include breakpoint(mobileonly) {
        width: 100%;
        padding: 2rem 1.5rem 0 1.5rem;
        margin-left: 0;
        margin-top: 2rem;
      }

      .title {
        margin-bottom: 2rem;
        width: 57rem;
        font-size: 2rem;
        color: var(--text-primary);
        font-family: 'WickedGrit';
        text-align: left;
        text-transform: uppercase;
        display: flex;
        align-items: flex-end;

        @include breakpoint(mobileonly) {
          font-size: 1.6rem;
        }

        span {
          z-index: 2;
          margin-top: 2rem;
        }

        @include breakpoint(phablet) {
          width: 100%;
        }

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        .shape-icon {
          height: 6.5rem;
          margin-left: -4rem;
          z-index: 1;

          @include breakpoint(mobileonly) {
            height: 4.5rem;
          }
        }
      }

      .contents {
        color: var(--text-primary);
        font-family: 'Baloo Da 2';
        width: 100%;

        @include breakpoint(phablet) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        @include breakpoint(mobileonly) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        .content-title {
          font-size: 3rem;
          color: var(--light-magenta);
          font-weight: bold;
          font-family: 'Baloo Da 2';
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @include breakpoint(mobileonly) {
            font-size: 2rem;
            margin: 1rem 0;
          }
        }

        .organiztion {
          font-family: 'Baloo Da 2';
          font-size: 1.16rem;
          color: #a0a3b1;
          font-weight: bold;
        }

        .description {
          font-family: 'Baloo Da 2';
          font-size: 1.4rem;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            background-color: #323649;
          }
        }

        .btn-container {
          display: flex;

          .btn {
            width: 15.25rem;
            height: 4rem;
            border-radius: 0.6rem;
            border: solid 2px #e1e2ea;
            color: var(--text-primary);
            font-size: 1.6rem !important;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
          }
        }
      }
    }
  }

  .fundraising-cont {
    height: 58.4rem;
    background-color: var(--bg-3);
  }

  .support {
    background-color: #34384a;
    .layer {
      display: flex;
      justify-content: space-between;
      background-position: center;
      background-color: #ffedff;
      border-bottom-right-radius: 8.33rem;
      padding: 5rem 7rem;
      align-items: flex-start;

      @include breakpoint(mobileonly) {
        flex-direction: column;
        padding: 5rem 1.5rem;
      }

      @include breakpoint(phablet) {
        flex-direction: column;
        padding: 5rem 1.5rem;
      }

      .heading {
        font-size: 2rem;
        color: var(--text-primary);
        font-family: 'Baloo Da 2';
        margin: 1rem 0;
        text-align: left;
        font-weight: 600;
      }

      .part-1 {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        @include breakpoint(phablet) {
          width: 100%;
        }

        .title {
          font-size: 1.33rem;
          color: var(--text-primary);
          font-family: 'Baloo Da 2';
          margin: 1rem 0;
          text-align: left;
        }

        .btn {
          width: 17.5rem;
          font-size: 1.6rem;
          text-transform: initial;
          font-weight: bold;
          background-color: white !important;
          border: 0.1rem solid #ed2bac !important;
          box-shadow: none !important;
          height: 3.33rem;
          border-radius: 6px;
          color: #ed2bac !important;

          &:hover {
            background-color: #ed2bac !important;
            color: white !important;
          }
        }
      }

      .part-2 {
        width: 38%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(mobileonly) {
          width: 100%;
        }

        @include breakpoint(phablet) {
          width: 100%;
        }

        .title {
          font-size: 1.33rem;
          color: var(--text-primary);
          font-family: 'Baloo Da 2';
          margin: 1rem 0;
          text-align: left;
        }

        .social-items {
          display: flex;
          margin: 2rem 0;
        }

        .items {
          .item {
            font-size: 1.2rem;
            color: #a0a3b1 !important;
            margin-bottom: 0.4rem;
          }

          .icon-cont {
            border-radius: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.8rem;
            cursor: pointer;
            margin-right: 2rem;
          }

          i {
            font-size: 2.2rem;
            color: #424557;
            margin-right: 1.2rem;

            &:hover {
              color: #ed2bac;
            }
          }
        }
      }
    }
  }
}
