@import '../../sass/abstracts/mixins';

.footer-cont {
  background-color: #323649;
  @include largeScreen();

  .part1 {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    font-family: 'Baloo Da 2';

    .part1-contents {
      width: 88%;
      display: flex;
      justify-content: space-between;

      @include breakpoint(mobileonly) {
        flex-direction: column;
      }

      @include breakpoint(mobileonly) {
        flex-direction: column;
      }

      .logo-cont {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
        color: #a0a3b1 !important;
        width: 16%;

        .logo {
          display: flex;
          align-items: flex-end;
          font-size: 1.33rem;
          line-height: 1;
          color: white;
          font-weight: bold;

          .image {
            height: 4rem;
          }

          .logo-text {
            margin-left: 0.2rem;
            letter-spacing: 1.1px;
          }
        }

        .icon-cont {
          display: flex;
          justify-content: flex-end;
          background-color: #323649 !important;
          color: #a0a3b1 !important;
          i {
            &:hover {
              color: var(--light-magenta) !important;
            }
          }
        }
      }

      .content-cont {
        width: 18%;
        @include breakpoint(mobileonly) {
          margin: 2rem 0;
          width: 100%;
        }

        @include breakpoint(mobileonly) {
          margin: 2rem 0;
          width: 100%;
        }

        .title {
          font-size: 1.4rem;
          text-transform: uppercase;
          color: #e1e2ea;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .social-items {
          display: flex;
        }

        .items {
          display: flex;
          flex-direction: column;
          .item {
            font-size: 1.2rem;
            color: #a0a3b1 !important;
            margin-bottom: 0.4rem;

            &:hover {
              color: var(--highlight-primary) !important;
            }
          }

          .coming-soon {
            font-size: 1.2rem;
            color: #a0a3b1 !important;
            margin-bottom: 0.4rem;
          }

          .icon-cont {
            height: 2rem;
            width: 2rem;
            border-radius: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.8rem;
            cursor: pointer;
            background-color: var(--light-magenta);
          }

          i {
            font-size: 1.4rem;
            color: white;
          }
        }
      }
    }
  }

  .part2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    font-family: 'Baloo Da 2';

    .part2-contents {
      width: 65%;
      display: flex;
      justify-content: center;

      @include breakpoint(mobileonly) {
        width: 100%;
      }

      @include breakpoint(mobileonly) {
        width: 100%;
      }

      .copyrights {
        font-size: 1.2rem;
        color: var(--text-secondary);
      }
    }
  }
}
