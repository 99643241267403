@import "../../../sass/abstracts/mixins";
.manage-team-container {
  .heading-1 {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: "Baloo Da 2";
    color: var(--text-primary);
    margin-bottom: 0.8rem;
  }
  .invite-team-member {
    display: flex;

    @include breakpoint(mobileonly) {
      flex-direction: column;
    }

    .input-part {
      width: 82%;
      margin-right: 2.2rem;

      @include breakpoint(mobileonly) {
        margin-bottom: 2rem;
        width: 100%;
      }

      .form-control {
        height: 4rem;
        border-radius: 1rem;
      }
    }

    .invite-btn-part {
      .invite-btn {
        font-family: "Baloo Da 2";
        text-transform: none;
        border: none;
        font-size: 1.6rem;
        border-radius: 0.6rem;
        width: 13.5rem;
        height: 4rem;
        margin: 0;
      }
    }
  }

  .heading-2 {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: "Baloo Da 2";
    color: var(--text-primary);
    margin: 4rem 0 0.2rem 0;
  }

  .table-cont {
    border: .1rem solid var(--bg-1);
    min-height: 10rem;
    max-height: 32rem;
    overflow: auto;
    margin-top: 1rem;
  }

  .table-head {
    background-color: var(--bg-1);
  }

  .memberslist-part {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;

    @include breakpoint(mobileonly) {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      border-bottom: solid 2px #e1e2ea;
      padding-bottom: 2rem;
    }

    .empty-circle-part {
      width: 3.33rem;
    }

    .circle-part {
      height: 3.33rem;
      width: 3.33rem;
      border-radius: 2.4rem;
      border: solid 0.2rem var(--bg-5);
      font-size: 1.6rem;
      color: #565a6c;
      font-family: "Baloo Da 2";
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(mobileonly) {
        margin-bottom: 1rem;
      }
    }

    .email-part {
      font-size: 1.6rem;
      color: #565a6c;
      font-family: "Baloo Da 2";
      width: 35%;

      @include breakpoint(mobileonly) {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 1rem;
      }
    }

    .status {
      width: 20%;
      color: #565a6c;
      font-family: "Baloo Da 2";
      font-size: 1.3rem;

      @include breakpoint(mobileonly) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .remove-btn-part {
      width: 15%;
      .remove-btn {
        height: 3.33rem;
        width: 10rem;
        border-radius: .4rem;
        border: solid 0.2rem var(--bg-5);
        font-size: 1.6rem;
        color: #565a6c;
        font-family: "Baloo Da 2";
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: white;
        box-shadow: none !important;
        margin: 0;
        font-weight: normal;

        &:hover {
          background-color: var(--bg-5);
          color: #565a6c;
        }
      }

      @include breakpoint(mobileonly) {
        margin-bottom: 1rem;
      }
    }
  }
}
