@import "../../../sass/abstracts/mixins";

.vc {
  width: calc((100% - 10rem) / 4);
  height: 31rem;
  overflow: hidden;
  border-radius: 0.6rem;
  border: solid 2px #eff0f5;
  cursor: pointer;
  transition: all 0.2s linear;
  margin-bottom: 2rem;
  margin-right: 2.5rem;
  position: relative;

  @include breakpoint(mobileonly) {
    width: calc((100% - 2rem) / 1);
    margin-right: 0;
  }

  @include breakpoint(phablet) {
    width: calc((100% - 2rem) / 1);
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 16px 32px 0 rgba(31, 34, 51, 0.16),
      0 8px 16px 0 rgba(31, 34, 51, 0.08);
  }

  &__image-wrapper {
    height: 16rem;
    overflow: hidden;
  }

  &__image {
    height: 100%;
    width: 100%;
    border-radius: 0.6rem;
  }

  &__info {
    padding: 1.2rem;
  }

  &__date {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--highlight-primary);
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--text-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__from {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-ternary);
  }
  
  &__venue {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-ternary);
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__footer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-secondary);
    margin-top: 1rem;
  }

  &__needed {
    margin-left: 1rem;
  }

  .drop-menu {
    margin-left: 2rem;
    width: 9rem;
    height: 3rem;
    background-color: white;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Baloo Da 2';
    padding: 1rem;
    border-radius: 0.3rem;
    position: absolute;
    right: 2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    top: 4%;

    .drop-name {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      cursor: pointer;
    }

    .drop {
      position: absolute;
      top: 105%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background-color: white;
      padding: 1rem;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      left: 0;
      z-index: 9;

      .items-drop {
        margin-bottom: 0.5rem;
        cursor: pointer;

        &:hover {
          color: var(--highlight-primary);
        }
      }
    }
  }

}
