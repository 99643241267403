@import "../../../../sass/abstracts/mixins";

.part-1-2 {
  width: 50%;
  background-image: url("../../../../images/Auth/helpinghand.jpg");
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  border-bottom-right-radius: 100px;

  @include breakpoint(mobileonly) {
    display: none;
  }

  @include breakpoint(phablet) {
    display: none;
    height: auto;
  }
}

.select-services-cont {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;

  .services {
    height: 2.5rem;
    border-radius: 0.3rem;
    background-color: #eaeaea;
    font-family: "Baloo Da 2";
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
    cursor: pointer;
    font-size: 1.4rem;
    padding: 1.2rem;

    &:hover {
      background-color: var(--text-secondary);
      color: white;
      transition: .2s all;
    }
  }

  .service-active {
    background-color: var(--vivid-green) !important;
    color: white !important;
  }
}

.btn {
  width: 10rem;
  font-family: "Baloo Da 2";
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: bold;
  margin: 2rem 0;
}