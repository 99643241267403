body {

  --highlight-primary: #ed2bac;
  --light-magenta: #fc4ae8;
  --vivid-green: #52f488;
  
  --bg-1: #f3f3f3;
  --bg-2: #ffffff;
  --bg-3: #fbf8f6;
  --bg-4: #eff0f5;
  --bg-5: #e1e2ea;
  --bg-6: #eff0f5;

  --text-primary: #424557;
  --text-secondary: #697084;
  --text-ternary: #cccccc;
  --text-four: #6583d9;

  --failed: rgb(196, 63, 63);
  --success: #14e528;
}
