@import '../../../sass/abstracts/mixins';

.my-donations-cont {
  font-family: 'Baloo Da 2';

  .header {
    margin: 0 0 2rem 0;
    z-index: 9;
    width: 100%;
    font-size: 2rem;
  }

  .my-donation-items {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(mobileonly) {
      flex-direction: column;
    }

    .no {
      width: 100%;
      display: flex;
      font-size: 1.6rem;
      color: var(--text-secondary);

      a {
        color: var(--highlight-primary) !important;
      }
    }

   
    .cause-card {
      text-align: center;
      object-fit: contain;
      padding: 1rem 1rem 2rem 1rem;
      border-radius: 0.3rem;
      width: calc((100% - 10rem) / 4);
      height: 25rem;
      cursor: default;
      transition: 0.3s all;
      margin-right: 2.5rem;
      border: solid 2px #eff0f5 !important;
      background-color: white;
      border-radius: 12px;
      margin-bottom: 2rem;

      @include breakpoint(mobileonly) {
        width: 100% !important;
      }

      &:hover {
        .cause-card__title {
          color: var(--text-primary);
        }
      }

      .amount-cont-wrapper {
        display: flex;
        justify-content: center;
        .amount-cont {
          height: 10rem;
          width: 10rem;
          border-radius: 50%;
          border: 0.3rem solid var(--highlight-primary);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          margin-bottom: 2rem;
          color: var(--highlight-primary);
        }
      }
    }

    .cause-card:hover {
      box-shadow: none !important;
    }
  }
}
